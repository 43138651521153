import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { PostListItem } from './post-list';

const TagListPage = (props) => {
  // console.log('tag-list-page');
  const { tag, posts } = props.pageContext;
  return (
    <React.Fragment>
      <SEO
        title={`posts about ${tag}`}
      />
      <h1>Posts tagged <em>{tag}</em></h1>
      <hr />
      {posts.map((post) => <PostListItem key={`pli-tlp-${post._id}`} post={post} />)}
    </React.Fragment>
  );
};

export default TagListPage;
